import { createSlice } from "@reduxjs/toolkit";

export const orderDetailSlice = createSlice({
  name: "orderDetail",
  initialState: {
    detail: null,
    refresh: false,
  },
  reducers: {
    setDetail: (state, action) => {
        state.refresh = false;
        state.detail = action.payload.detail;
    },
    setRefresh: (state) => {
      state.refresh = true;
    },
  },
});

export const {
    setDetail,
    setRefresh
} = orderDetailSlice.actions;
