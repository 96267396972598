import { memo, useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.fieldError === nextProps.fieldError &&
    prevProps.optionItems === nextProps.optionItems &&
    prevProps.handlerParam === nextProps.handlerParam &&
    prevProps.value === nextProps.value
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};

const InputField = ({
  required,
  fullWidth,
  id,
  label,
  name,
  fieldError,
  value,
  onValueChanged,
  startIcon,
  endIcon,
  margin,
  CustomInputComponent,
  optionItems,
  helperText,
  handlerParam,
  onInputBlur,
  noUnderline, // hanya untuk variant standard dan filled
  ...props
}) => {
  const onChangeHandler = (e) => {
    if (typeof handlerParam === 'undefined') {
      onValueChanged(e);
    } else {
      onValueChanged(e, handlerParam);
    }
  }

  let disUnderline = {};
  if(!!noUnderline) {
    disUnderline.disableUnderline = true;
  }

  return (
    <TextField
      {...props}
      error={fieldError ? true : false}
      helperText={helperText ? helperText : fieldError}
      margin={margin && typeof margin === "string" ? margin : "none"}
      required={!!required}
      fullWidth={!!fullWidth}
      id={id}
      label={label}
      name={name}
      value={value}
      onChange={onChangeHandler}
      onBlur={onInputBlur ? onInputBlur : () => {}}
      InputProps={props.multiline ? {} : {
        inputComponent: CustomInputComponent ? CustomInputComponent : null,
        startAdornment: startIcon ? (
          <InputAdornment position="start">{startIcon}</InputAdornment>
        ) : null,
        endAdornment: endIcon ? (
          <InputAdornment position="end">{endIcon}</InputAdornment>
        ) : null,
        ...disUnderline
      }}
    >
      {!!optionItems ? optionItems.map((option) => (
        <MenuItem key={option.val} value={option.val}>
          {option.lbl}
        </MenuItem>
      )) : null}
    </TextField>
  );
};
export default memo(InputField, propsAreEqual);
