import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import api from "../../../utils/axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Dropzone from "../../../components/image_dropzone/Dropzone";
import { setOpen as setSnackbarOpen } from "../../../components/flash_info/flashInfoSlice";
import { useDispatch } from "react-redux";
import ActionButtons from "../../../components/form_buttons/ActionButtons";
import InputField from "../../../components/input_field/InputField";

const CertificationForm = (props) => {
  const [imageError, setImageError] = useState("");
  const [displayOrderError, setDisplayOrderError] = useState("");

  const [displayOrder, setDisplayOrder] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [filesWereDropped, setFilesWereDropped] = useState(false);

  const [files, setFiles] = useState([]);

  const navigate = useNavigate();
  const { certificationId } = useParams();
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    const abortController = new AbortController();
    const accessToken = localStorage.cjfiAdmTkn;
    if (location.pathname.includes("edit")) {
      (async () => {
        try {
          const res = await api.get(
            "/api/admin/v1/certifications/" + certificationId,
            {
              signal: abortController.signal,
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );
          if (res.status === 200) {
            if (res.data.code === 200) {
              const { im, d } = res.data.certification;
              // im harus berbentuk array of object. contoh:
              // [{preview: url, name: ..., size: ..., path: ...}, {}, {}]
              // harus ada path isinya nama file
              setFiles(im); // name & size dipake di Dropzone.js
              setDisplayOrder(d);
            }
          }
        } catch ({ response = {} }) {
          if (!abortController.signal.aborted) {
            dispatch(
              setSnackbarOpen({ text: response.data.message, type: "error" })
            );
          }
        }
      })();
    }
    return () => {
      abortController.abort();
    };
  }, [certificationId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setImageError("");
    setDisplayOrderError("");
    setIsLoading(true);

    const formData = new FormData(event.currentTarget);
    formData.delete("choose_image");
    // lihat: https://github.com/react-dropzone/react-dropzone/issues/131#issuecomment-264949671
    // https://github.com/ucdavis/CaesHelp/issues/64
    if (filesWereDropped) {
      files.forEach((file) => {
        formData.set("im[]", file);
      });
    }

    try {
      const accessToken = localStorage.cjfiAdmTkn;
      // const resCsrfToken = await api.get("/sanctum/csrf-cookie");
      if (location.pathname.includes("edit")) {
        formData.append("_method", "PATCH");
      }
      const { status, data } = await api.post(
        "/api/admin/v1/certifications/" +
          (typeof certificationId === "undefined" ? "" : certificationId),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status === 200 || status === 201) {
        const searchParams = location.state
          ? location.state.from
            ? location.state.from
            : ""
          : "";
        navigate("/certifications" + searchParams);
      }
      setIsLoading(false);
      dispatch(setSnackbarOpen({ text: data.message }));
    } catch ({ response = {} }) {
      setIsLoading(false);
      if (response.data) {
        dispatch(
          setSnackbarOpen({ text: response.data.message, type: "error" })
        );
        if (response.data.errors) {
          const errMsgs = response.data.errors;

          Object.keys(errMsgs).map((fieldName, index) => {
            const errMsg = errMsgs[fieldName][0];

            switch (fieldName) {
              case "im":
                setImageError(errMsg);
                break;
              case "d":
                setDisplayOrderError(errMsg);
                break;
              default:
                break;
            }
          });
        }
      }
    }
  };

  const onDisplayOrderChangeHandler = (e) => {
    setDisplayOrder(e.target.value);
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <Dropzone
          autoFocus
          required
          label="Choose Image"
          fileError={imageError}
          inputName="im[]"
          files={files}
          setFiles={setFiles}
          setFilesWereDropped={setFilesWereDropped}
        />
        <InputField
          fullWidth
          fieldError={displayOrderError}
          value={displayOrder}
          onValueChanged={onDisplayOrderChangeHandler}
          label="Display Order"
          name="d"
          margin="normal"
          type="number"
        />
        <ActionButtons isLoading={isLoading} backTo="/certifications" />
      </Box>
    </Paper>
  );
};

export default CertificationForm;
