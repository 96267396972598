import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import api from "../../../utils/axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Dropzone from "../../../components/image_dropzone/Dropzone";
import { setOpen as setSnackbarOpen } from "../../../components/flash_info/flashInfoSlice";
import { useDispatch } from "react-redux";
import ActionButtons from "../../../components/form_buttons/ActionButtons";
import InputField from "../../../components/input_field/InputField";

const WhyChooseUsForm = (props) => {
  const [imageError, setImageError] = useState("");
  const [titleIdError, setTitleIdError] = useState("");
  const [titleEnError, setTitleEnError] = useState("");
  const [displayOrderError, setDisplayOrderError] = useState("");

  const [titleId, setTitleId] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [displayOrder, setDisplayOrder] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [filesWereDropped, setFilesWereDropped] = useState(false);

  const [files, setFiles] = useState([]);

  const navigate = useNavigate();
  const { whyChooseUsId } = useParams();
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    const abortController = new AbortController();
    const accessToken = localStorage.cjfiAdmTkn;
    if (location.pathname.includes("edit")) {
      (async () => {
        try {
          const res = await api.get(
            "/api/admin/v1/why-choose-us/" + whyChooseUsId,
            {
              signal: abortController.signal,
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );
          if (res.status === 200) {
            if (res.data.code === 200) {
              const { im, ti, te, d } = res.data.whyChooseUs;
              // im harus berbentuk array of object. contoh:
              // [{preview: url, name: ..., size: ..., path: ...}, {}, {}]
              // harus ada path isinya nama file
              setFiles(im); // name & size dipake di Dropzone.js
              setTitleId(ti);
              setTitleEn(te);
              setDisplayOrder(d);
            }
          }
        } catch ({ response = {} }) {
          if (!abortController.signal.aborted) {
            dispatch(
              setSnackbarOpen({ text: response.data.message, type: "error" })
            );
          }
        }
      })();
    }
    return () => {
      abortController.abort();
    };
  }, [whyChooseUsId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setImageError("");
    setDisplayOrderError("");
    setIsLoading(true);

    const formData = new FormData(event.currentTarget);
    formData.delete("choose_image");
    // lihat: https://github.com/react-dropzone/react-dropzone/issues/131#issuecomment-264949671
    // https://github.com/ucdavis/CaesHelp/issues/64
    if (filesWereDropped) {
      files.forEach((file) => {
        formData.set("im[]", file);
      });
    }

    try {
      const accessToken = localStorage.cjfiAdmTkn;
      // const resCsrfToken = await api.get("/sanctum/csrf-cookie");
      if (location.pathname.includes("edit")) {
        formData.append("_method", "PATCH");
      }
      const { status, data } = await api.post(
        "/api/admin/v1/why-choose-us/" +
          (typeof whyChooseUsId === "undefined" ? "" : whyChooseUsId),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status === 200 || status === 201) {
        const searchParams = location.state
          ? location.state.from
            ? location.state.from
            : ""
          : "";
        navigate("/why-choose-us" + searchParams);
      }
      setIsLoading(false);
      dispatch(setSnackbarOpen({ text: data.message }));
    } catch ({ response = {} }) {
      setIsLoading(false);
      if (response.data) {
        dispatch(
          setSnackbarOpen({ text: response.data.message, type: "error" })
        );
        if (response.data.errors) {
          const errMsgs = response.data.errors;

          Object.keys(errMsgs).map((fieldName, index) => {
            const errMsg = errMsgs[fieldName][0];

            switch (fieldName) {
              case "im":
                setImageError(errMsg);
                break;
              case "ti":
                setTitleIdError(errMsg);
                break;
              case "te":
                setTitleEnError(errMsg);
                break;
              case "d":
                setDisplayOrderError(errMsg);
                break;
              default:
                break;
            }
          });
        }
      }
    }
  };

  const onTitleIdChangeHandler = (e) => {
    setTitleId(e.target.value);
  };
  const onTitleEnChangeHandler = (e) => {
    setTitleEn(e.target.value);
  };
  const onDisplayOrderChangeHandler = (e) => {
    setDisplayOrder(e.target.value);
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <Dropzone
          autoFocus
          required
          label="Choose Image"
          fileError={imageError}
          inputName="im[]"
          files={files}
          setFiles={setFiles}
          setFilesWereDropped={setFilesWereDropped}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <InputField
              fullWidth
              fieldError={titleIdError}
              value={titleId}
              onValueChanged={onTitleIdChangeHandler}
              label="Title Indonesian"
              name="ti"
              margin="normal"
              multiline={true}
              required={true}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputField
              fullWidth
              fieldError={titleEnError}
              value={titleEn}
              onValueChanged={onTitleEnChangeHandler}
              label="Title English"
              name="te"
              margin="normal"
              multiline={true}
            />
          </Grid>
        </Grid>
        <InputField
          fullWidth
          fieldError={displayOrderError}
          value={displayOrder}
          onValueChanged={onDisplayOrderChangeHandler}
          label="Display Order"
          name="d"
          margin="normal"
          type="number"
          required={true}
        />
        <ActionButtons isLoading={isLoading} backTo="/why-choose-us" />
      </Box>
    </Paper>
  );
};

export default WhyChooseUsForm;
