import loadable from "@loadable/component";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsGuest, setCurrentUser } from "./components/auth/authSlice";
import api from "./utils/axios";
import ContactUsIndex from "./page/contact_us/ContactUsIndex";
import DesignInspirationIndex from "./page/design_inspiration/DesignInspirationIndex";
import DesignInspirationForm from "./page/design_inspiration/form_components/DesignInspirationForm";
import { setUserRoles } from "./components/auth/authSlice";
import UserForm from "./page/users/form_components/UserForm";
import DownloadRequestIndex from "./page/download_request/DownloadRequestIndex";
import CertificationIndex from "./page/certifications/CertificationIndex";
import CertificationForm from "./page/certifications/form_components/CertificationForm";
import WhyChooseUsIndex from "./page/why_choose_us/WhyChooseUsIndex";
import WhyChooseUsForm from "./page/why_choose_us/form_components/WhyChooseUsForm";

const OrderIndex = loadable(() => import("./page/orders/OrderIndex"));
const OrderDetail = loadable(() => import("./page/orders/OrderDetail"));

const BrandIndex = loadable(() => import("./page/brands/BrandIndex"));
const BrandForm = loadable(() =>
  import("./page/brands/form_components/BrandForm")
);
const TypeIndex = loadable(() => import("./page/types/TypeIndex"));
const TypeForm = loadable(() =>
  import("./page/types/form_components/TypeForm")
);
// const MotifIndex = loadable(() => import("./page/motifs/MotifIndex"));
// const MotifForm = loadable(() =>
//   import("./page/motifs/form_components/MotifForm")
// );
const SizeIndex = loadable(() => import("./page/sizes/SizeIndex"));
const SizeForm = loadable(() =>
  import("./page/sizes/form_components/SizeForm")
);
const ColorIndex = loadable(() => import("./page/colors/ColorIndex"));
const ColorForm = loadable(() =>
  import("./page/colors/form_components/ColorForm")
);
const MotifDesignIndex = loadable(() =>
  import("./page/motif_designs/MotifDesignIndex")
);
const MotifDesignForm = loadable(() =>
  import("./page/motif_designs/form_components/MotifDesignForm")
);
const SurfaceIndex = loadable(() => import("./page/surfaces/SurfaceIndex"));
const SurfaceForm = loadable(() =>
  import("./page/surfaces/form_components/SurfaceForm")
);
const CeramicIndex = loadable(() => import("./page/ceramics/CeramicIndex"));
const CeramicForm = loadable(() =>
  import("./page/ceramics/form_components/CeramicForm")
);

const AboutIndex = loadable(() => import("./page/about/AboutIndex"));
const AboutForm = loadable(() =>
  import("./page/about/form_components/AboutForm")
);

const ContactCoverageIndex = loadable(() =>
  import("./page/contact_coverage/ContactCoverageIndex")
);
const ContactCoverageForm = loadable(() =>
  import("./page/contact_coverage/form_components/ContactCoverageForm")
);

const DistributionIndex = loadable(() =>
  import("./page/distribution/DistributionIndex")
);
const DistributionForm = loadable(() =>
  import("./page/distribution/form_components/domestic/DistributionForm")
);
const OverseasDistributionForm = loadable(() =>
  import("./page/distribution/form_components/overseas/DistributionForm")
);

const ProjectReferenceIndex = loadable(() =>
  import("./page/project_references/ProjectReferenceIndex")
);
const ProjectReferenceForm = loadable(() =>
  import("./page/project_references/form_components/ProjectReferenceForm")
);

const CareerIndex = loadable(() => import("./page/careers/CareerIndex"));
const CareerForm = loadable(() =>
  import("./page/careers/form_components/CareerForm")
);

const NewsIndex = loadable(() => import("./page/news/NewsIndex"));
const NewsForm = loadable(() => import("./page/news/form_components/NewsForm"));

const GuideTipsIndex = loadable(() =>
  import("./page/guide_tips/GuideTipsIndex")
);
const GuideTipsForm = loadable(() =>
  import("./page/guide_tips/form_components/GuideTipsForm")
);
const FaqIndex = loadable(() => import("./page/faqs/FaqIndex"));
const FaqForm = loadable(() => import("./page/faqs/form_components/FaqForm"));
const DownloadIndex = loadable(() => import("./page/downloads/DownloadIndex"));
const DownloadForm = loadable(() =>
  import("./page/downloads/form_components/DownloadForm")
);
const Dashboard = loadable(() => import("./layouts/admin/Dashboard"));
const ConfirmationDialog = loadable(() =>
  import("./components/confirmation_dialog/ConfirmationDialog")
);
const UsersIndex = loadable(() => import("./page/users/UsersIndex"));
const ActivitiesIndex = loadable(() =>
  import("./page/activities/ActivitiesIndex")
);
const FlashInfo = loadable(() => import("./components/flash_info/flashInfo"));
const NotFound = loadable(() => import("./page/not_found/NotFound"));
const Login = loadable(() => import("./page/login/Login"));
const BannerIndex = loadable(() => import("./page/banners/BannerIndex"));
const BannerForm = loadable(() =>
  import("./page/banners/form_components/BannerForm")
);
const UKRKBannerIndex = loadable(() =>
  import("./page/ukrk_banners/UKRKBannerIndex")
);
const UKRKBannerForm = loadable(() =>
  import("./page/ukrk_banners/form_components/UKRKBannerForm")
);
const UKRKDescIndex = loadable(() => import("./page/ukrk_desc/UKRKDescIndex"));
const UKRKDescForm = loadable(() =>
  import("./page/ukrk_desc/form_components/UKRKDescForm")
);
const UKRKProductIndex = loadable(() =>
  import("./page/ukrk_products/UKRKProductIndex")
);
const UKRKProductForm = loadable(() =>
  import("./page/ukrk_products/form_components/UKRKProductForm")
);
const UKRKAnatomyIndex = loadable(() =>
  import("./page/ukrk_anatomies/UKRKAnatomyIndex")
);
const UKRKAnatomyForm = loadable(() =>
  import("./page/ukrk_anatomies/form_components/UKRKAnatomyForm")
);

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isGuest } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // untuk selalu mengecek status user apakah login atau guest
  useEffect(() => {
    const accessToken = localStorage.cjfiAdmTkn;
    if (accessToken) {
      (async () => {
        try {
          const { status, data } = await api.get("/api/user", {
            headers: { Authorization: `Bearer ${accessToken}` },
          });
          if (status === 200) {
            if (data.code === 200) {
              dispatch(setIsGuest({ isGuest: data.data.isGuest }));
              dispatch(setCurrentUser({ currentUser: data.data.user }));
              dispatch(
                setUserRoles({
                  userRoles: data.data.roles ? data.data.roles : [],
                })
              );
            }
          }
        } catch (error) {}
      })();
    } else {
      dispatch(setIsGuest({ isGuest: true }));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isGuest) {
      if (location.pathname !== "/login") {
        navigate(location.state ? location.state.from.pathname : "/login");
      }
    } else if (isGuest === false) {
      if (location.pathname === "/login") {
        navigate(location.state ? location.state.from.pathname : "/");
      }
    } else if (isGuest === null) {
      if (location.pathname === "/login") {
      }
    }
  });

  return (
    <>
      <Routes>
        <Route
          element={
            <Dashboard fallback={<div>Loading...</div>} isGuest={isGuest} />
          }
        >
          {" "}
          {/* cara kerja layout lihat: https://stackoverflow.com/a/69982280 */}
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/dashboard" element={null} />
          {/* ORDER */}
          {/* <Route path="/orders">
            <Route index element={<OrderIndex />} />
            <Route path="detail/:orderNum" element={<OrderDetail />} />
          </Route> */}
          {/* BANNER */}
          <Route path="/banners">
            <Route index element={<BannerIndex />} />
            <Route path="add" element={<BannerForm />} />
            <Route path="edit/:bannerId" element={<BannerForm />} />
          </Route>
          {/* CERAMIC */}
          <Route path="/ceramics">
            <Route index element={<CeramicIndex />} />
            <Route path="add" element={<CeramicForm />} />
            <Route path="edit/:ceramicId" element={<CeramicForm />} />
          </Route>
          {/* ABOUT */}
          <Route path="/about">
            <Route index element={<AboutIndex />} />
            <Route path="add" element={<AboutForm />} />
            <Route path="detail/:aboutId" element={<AboutIndex />} />
            <Route path="edit/:aboutId" element={<AboutForm />} />
          </Route>
          {/* DISTRIBUTION */}
          <Route path="/distribution">
            <Route index element={<DistributionIndex />} />
            <Route path="domestic/add" element={<DistributionForm />} />
            <Route
              path="domestic/detail/:distriId"
              element={<DistributionIndex />}
            />
            <Route
              path="domestic/edit/:distriId"
              element={<DistributionForm />}
            />
            <Route path="overseas/add" element={<OverseasDistributionForm />} />
            <Route
              path="overseas/edit/:distriId"
              element={<OverseasDistributionForm />}
            />
          </Route>
          {/* CONTACT COVERAGE */}
          <Route path="/contact-coverage">
            <Route index element={<ContactCoverageIndex />} />
            <Route path="add" element={<ContactCoverageForm />} />
            <Route
              path="detail/:contactId"
              element={<ContactCoverageIndex />}
            />
            <Route path="edit/:contactId" element={<ContactCoverageForm />} />
          </Route>
          {/* PROJECT REFERENCE */}
          <Route path="/project-references">
            <Route index element={<ProjectReferenceIndex />} />
            <Route path="add" element={<ProjectReferenceForm />} />
            <Route
              path="detail/:projectReferenceId"
              element={<ProjectReferenceIndex />}
            />
            <Route
              path="edit/:projectReferenceId"
              element={<ProjectReferenceForm />}
            />
          </Route>
          {/* CAREER */}
          <Route path="/careers">
            <Route index element={<CareerIndex />} />
            <Route path="add" element={<CareerForm />} />
            <Route path="detail/:careerId" element={<CareerIndex />} />
            <Route path="edit/:careerId" element={<CareerForm />} />
          </Route>
          {/* NEWS */}
          <Route path="/news">
            <Route index element={<NewsIndex />} />
            <Route path="add" element={<NewsForm />} />
            <Route path="detail/:newsId" element={<NewsIndex />} />
            <Route path="edit/:newsId" element={<NewsForm />} />
          </Route>
          {/* GUIDE TIPS */}
          {/* <Route path="/guide-tips">
            <Route index element={<GuideTipsIndex />} />
            <Route path="add" element={<GuideTipsForm />} />
            <Route path="detail/:guideTipsId" element={<GuideTipsIndex />} />
            <Route path="edit/:guideTipsId" element={<GuideTipsForm />} />
          </Route> */}
          {/* TIPS AND TRICK */}
          <Route path="/tips-trick">
            <Route index element={<FaqIndex />} />
            <Route path="add" element={<FaqForm />} />
            <Route path="detail/:faqId" element={<FaqIndex />} />
            <Route path="edit/:faqId" element={<FaqForm />} />
          </Route>
          {/* DOWNLOAD */}
          <Route path="/downloads">
            <Route index element={<DownloadIndex />} />
            <Route path="add" element={<DownloadForm />} />
            <Route path="detail/:downloadId" element={<DownloadIndex />} />
            <Route path="edit/:downloadId" element={<DownloadForm />} />
          </Route>
          {/* INSPIRATION DESIGN */}
          <Route path="/design-inspirations">
            <Route index element={<DesignInspirationIndex />} />
            <Route path="add" element={<DesignInspirationForm />} />
            <Route
              path="edit/:designInspirationId"
              element={<DesignInspirationForm />}
            />
          </Route>
          {/* CONTACT US */}
          <Route path="/contact-us">
            <Route index element={<ContactUsIndex />} />
          </Route>
          {/* DOWNLOAD REQUEST */}
          <Route path="/download-request">
            <Route index element={<DownloadRequestIndex />} />
          </Route>
          {/* CERTIFICATION */}
          <Route path="/certifications">
            <Route index element={<CertificationIndex />} />
            <Route path="add" element={<CertificationForm />} />
            <Route
              path="edit/:certificationId"
              element={<CertificationForm />}
            />
          </Route>
          {/* WHY CHOOSE US */}
          <Route path="/why-choose-us">
            <Route index element={<WhyChooseUsIndex />} />
            <Route path="add" element={<WhyChooseUsForm />} />
            <Route path="edit/:whyChooseUsId" element={<WhyChooseUsForm />} />
          </Route>
          {/* UKRK */}
          <Route path="/ukrk-banners">
            <Route index element={<UKRKBannerIndex />} />
            <Route path="add" element={<UKRKBannerForm />} />
            <Route path="edit/:ukrkBannerId" element={<UKRKBannerForm />} />
          </Route>
          <Route path="/ukrk-desc">
            <Route index element={<UKRKDescIndex />} />
            <Route path="add" element={<UKRKDescForm />} />
            <Route path="edit/:ukrkDescId" element={<UKRKDescForm />} />
          </Route>
          <Route path="/ukrk-products">
            <Route index element={<UKRKProductIndex />} />
            <Route path="add" element={<UKRKProductForm />} />
            <Route path="edit/:ukrkProductId" element={<UKRKProductForm />} />
          </Route>
          <Route path="/ukrk-anatomies">
            <Route index element={<UKRKAnatomyIndex />} />
            <Route path="add" element={<UKRKAnatomyForm />} />
            <Route path="edit/:ukrkAnatomyId" element={<UKRKAnatomyForm />} />
          </Route>
          {/* MASTER BRANDS */}
          <Route path="/brands">
            <Route index element={<BrandIndex />} />
            <Route path="add" element={<BrandForm />} />
            <Route path="edit/:brandId" element={<BrandForm />} />
          </Route>
          {/* MASTER APPLICATION */}
          <Route path="/applications">
            <Route index element={<TypeIndex />} />
            <Route path="add" element={<TypeForm />} />
            <Route path="edit/:typeId" element={<TypeForm />} />
          </Route>
          {/* MASTER MOTIFS */}
          {/* <Route path="/motifs">
            <Route index element={<MotifIndex />} />
            <Route path="add" element={<MotifForm />} />
            <Route path="edit/:motifId" element={<MotifForm />} />
          </Route> */}
          {/* MASTER SIZES */}
          <Route path="/sizes">
            <Route index element={<SizeIndex />} />
            <Route path="add" element={<SizeForm />} />
            <Route path="edit/:sizeId" element={<SizeForm />} />
          </Route>
          {/* MASTER COLORS */}
          <Route path="/colors">
            <Route index element={<ColorIndex />} />
            <Route path="add" element={<ColorForm />} />
            <Route path="edit/:colorId" element={<ColorForm />} />
          </Route>
          {/* MASTER MOTIF DESIGN */}
          <Route path="/motif-designs">
            <Route index element={<MotifDesignIndex />} />
            <Route path="add" element={<MotifDesignForm />} />
            <Route path="edit/:motifDesignId" element={<MotifDesignForm />} />
          </Route>
          {/* MASTER SURFACE */}
          <Route path="/surfaces">
            <Route index element={<SurfaceIndex />} />
            <Route path="add" element={<SurfaceForm />} />
            <Route path="edit/:surfaceId" element={<SurfaceForm />} />
          </Route>
          {/* USERS */}
          <Route path="/users">
            <Route path="admins">
              <Route index element={<UsersIndex />} />
              <Route path="add" element={<UserForm />} />
              <Route path="edit/:userId" element={<UserForm />} />
            </Route>
            <Route path="customers" element={<UsersIndex />} />
          </Route>
          {/* ACTIVITIES */}
          <Route path="/activities">
            <Route path="admins" element={<ActivitiesIndex />} />
            <Route path="customers" element={<ActivitiesIndex />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ConfirmationDialog />
      <FlashInfo />
    </>
  );
};

export default App;
