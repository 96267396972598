import { createSlice } from "@reduxjs/toolkit";

export const DELETE_BANNER = "deleteBanner";
export const DELETE_UKRK_BANNER = "deleteUKRKBanner";
export const DELETE_UKRK_PRODUCT = "deleteUKRKProduct";
export const DELETE_UKRK_DESC = "deleteUKRKDesc";
export const DELETE_UKRK_ANATOMY = "deleteUKRKAnatomy";
export const DELETE_BRAND = "deleteBrand";
export const DELETE_TYPE = "deleteType";
export const DELETE_MOTIF = "deleteMotif";
export const DELETE_SIZE = "deleteSize";
export const DELETE_CERAMIC = "deleteCeramic";
export const DELETE_FAQ = "deleteFaq";
export const DELETE_DOWNLOAD = "deleteDownload";
export const DELETE_GUIDE_TIPS = "deleteGuideTips";
export const DELETE_NEWS = "deleteNews";
export const DELETE_ABOUT = "deleteAbout";
export const DELETE_ENV = "deleteEnv";
export const DELETE_DOMESTIC_DISTRIBUTION = "deleteDomesticDistribution";
export const DELETE_OVERSEAS_DISTRIBUTION = "deleteOverseasDistribution";
export const DELETE_PROJECT_REFERENCE = "deleteProjectReference";
export const DELETE_DESIGN_INSPIRATION = "deleteDesignInspiration";
export const DELETE_CONTACT_COVERAGE = "deleteContactCoverage";
export const DELETE_CONTACT_US = "deleteContactUs";
export const DELETE_DOWNREQ = "deleteDownloadRequest";
export const DELETE_CERTIFICATION = "deleteCertification";
export const DELETE_WHY_CHOOSE_US = "deleteWhyChooseUs";
export const DELETE_CAREER = "deleteCareer";
export const DELETE_ADMIN = "deleteAdmin";
export const DELETE_COLOR = "deleteColor";
export const DELETE_MOTIF_DESIGN = "deleteMotifDesign";
export const DELETE_SURFACE = "deleteSurface";
export const DELIVER_ORDER = "deliverOrder";
export const DELIVERED_ORDER = "deliveredOrder";
export const CANCEL_ORDER = "cancelOrder";
export const APPROVE_PAYMENT = "approvePayment";
export const DENY_PAYMENT = "denyPayment";
export const REFUND_PAYMENT = "refundPayment";

export const confirmationDialogSlice = createSlice({
  name: "confirmationDialog",
  initialState: {
    open: false,
    title: "",
    description: "",
    confirmFor: "", // contoh isinya 'deleteBanner'|'deleteProduct'
    isLoading: false,
    // jenis-jenis actions
    deleteBanner: false,
    deleteUKRKBanner: false,
    deleteUKRKProduct: false,
    deleteUKRKDesc: false,
    deleteUKRKAnatomy: false,
    deleteBrand: false,
    deleteType: false,
    deleteMotif: false,
    deleteSize: false,
    deleteCeramic: false,
    deleteFaq: false,
    deleteDownload: false,
    deleteGuideTips: false,
    deleteNews: false,
    deleteAbout: false,
    deleteEnv: false,
    deleteDomesticDistribution: false,
    deleteOverseasDistribution: false,
    deleteProjectReference: false,
    deleteDesignInspiration: false,
    deleteContactCoverage: false,
    deleteContactUs: false,
    deleteDownloadRequest: false,
    deleteCertification: false,
    deleteWhyChooseUs: false,
    deleteCareer: false,
    deleteAdmin: false,
    deleteColor: false,
    deleteMotifDesign: false,
    deleteSurface: false,
    deliverOrder: false,
    deliveredOrder: false,
    cancelOrder: false,
    approvePayment: false,
    denyPayment: false,
    refundPayment: false,
  },
  reducers: {
    setOpen: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.open = true;
    },
    setClose: (state) => {
      state.open = false;
      // state.title = "";
      // state.description = "";
      state.confirmFor = "";
      state.isLoading = false;
      state.deleteBanner = false;
      state.deleteUKRKBanner = false;
      state.deleteUKRKProduct = false;
      state.deleteUKRKDesc = false;
      state.deleteUKRKAnatomy = false;
      state.deleteBrand = false;
      state.deleteType = false;
      state.deleteMotif = false;
      state.deleteSize = false;
      state.deleteCeramic = false;
      state.deleteFaq = false;
      state.deleteDownload = false;
      state.deleteGuideTips = false;
      state.deleteNews = false;
      state.deleteAbout = false;
      state.deleteEnv = false;
      state.deleteDomesticDistribution = false;
      state.deleteOverseasDistribution = false;
      state.deleteProjectReference = false;
      state.deleteDesignInspiration = false;
      state.deleteContactCoverage = false;
      state.deleteContactUs = false;
      state.deleteDownloadRequest = false;
      state.deleteCertification = false;
      state.deleteWhyChooseUs = false;
      state.deleteCareer = false;
      state.deleteAdmin = false;
      state.deleteColor = false;
      state.deleteMotifDesign = false;
      state.deleteSurface = false;
      state.deliverOrder = false;
      state.deliveredOrder = false;
      state.cancelOrder = false;
      state.approvePayment = false;
      state.denyPayment = false;
      state.refundPayment = false;
    },
    setTitle: (state, action) => {
      state.title = action.payload.title;
    },
    setDescription: (state, action) => {
      state.description = action.payload.description;
    },
    setConfirmFor: (state, action) => {
      state.confirmFor = action.payload.confirmFor; // contoh isinya payload.confirmFor: 'deleteBanner'|'deleteProduct'
    },
    setConfirmed: (state, action) => {
      // confirmFor contoh isinya 'deleteBanner'|'deleteProduct'
      state[action.payload.confirmFor] = true;
    },
    setLoading: (state, action) => {
      // confirmFor contoh isinya 'deleteBanner'|'deleteProduct'
      state.isLoading = action.payload.isLoading;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setOpen,
  setClose,
  setTitle,
  setDescription,
  setConfirmFor,
  setConfirmed,
  setLoading,
} = confirmationDialogSlice.actions;

export default confirmationDialogSlice.reducer;
