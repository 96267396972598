import axios from "axios";
import history from "./routeHistory";

const api = axios.create({
  baseURL:
    process.env.NODE_ENV !== "production"
      ? "http://127.0.0.1:8000"
      : "https://api.cjfi.co.id",
  // baseURL: "http://217.160.33.53:7413",
  withCredentials: true,
});

api.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("cjfiAdmTkn");
      history.replace("/login");
      return Promise.reject();
    }
    return Promise.reject(error);
  }
);

export default api;
