import { createSlice } from "@reduxjs/toolkit";

export const downloadDetailDialogSlice = createSlice({
    name: "downloadDetailDialog",
    initialState: {
        open: false,
        title: "",
        fileUrl: "",
        size: "",
        createdAt: "",
        updatedAt: "",
        isLoading: false,
    },
    reducers: {
        setOpen: (state) => {
            state.open = true;
        },
        setClose: (state) => {
            state.open = false;
        },
        setTitle: (state, action) => {
            state.title = action.payload.title;
        },
        setFileUrl: (state, action) => {
            state.fileUrl = action.payload.fileUrl;
        },
        setSize: (state, action) => {
            state.size = action.payload.size;
        },
        setCreatedAt: (state, action) => {
            state.createdAt = action.payload.createdAt;
        },
        setUpdatedAt: (state, action) => {
            state.updatedAt = action.payload.updatedAt;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload.isLoading;
        }
    }
});

export const { setOpen, setClose, setTitle, setFileUrl, setSize, setCreatedAt, setUpdatedAt, setIsLoading } = downloadDetailDialogSlice.actions;

export default downloadDetailDialogSlice.reducer;