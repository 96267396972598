import { memo, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
// import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import classes from "./Dropzone.module.css";
import readableFileSize from "../../utils/fileSize";
import { useDropzone } from "react-dropzone";
import TextField from "@mui/material/TextField";
import { toSnakeCase, upperCaseFirstLetter } from "../../utils/formatter";
import { InputAdornment } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const Dropzone = ({
  autoFocus,
  required,
  icon,
  label,
  fileError,
  multiple,
  hintText,
  inputName,
  files,
  setFiles,
  setFilesToBeDeleted,
  setFilesWereDropped,
  hideInput,
  customClassNames, // class name bentuk string masing2 dipisah dengan spasi
  CustomThumbnail, // function component
  accept,
}) => {
  const [readyForDrop, setReadyForDrop] = useState(false);
  const [fileName, setFileName] = useState("");

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    rootRef,
    inputRef,
    open: openFileDialog,
  } = useDropzone({
    accept: typeof accept === "string" ? accept : "image/*",
    multiple: !!multiple,
    onDrop: (acceptedFiles, _, e) => {
      setReadyForDrop(false);
      // lihat: https://github.com/react-dropzone/react-dropzone/issues/131#issuecomment-264949671
      // https://github.com/ucdavis/CaesHelp/issues/64
      setFilesWereDropped(!e.target.files || e.target.files.length === 0);

      setFiles(
        acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        })
      );
    },
  });

  const style = useMemo(
    () => ({
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  useEffect(() => {
    const totFile = files.length;
    if (totFile > 1) {
      setFileName(totFile + " files selected");
    } else if (totFile === 1) {
      setFileName(files[0].path);
    } else {
      setFileName(""); // handle kalau semua image yg sudah di select dihapus, tp ini tidak berguna ketika multiple false, tombol hapus untuk logo tidak ada
    }
  }, [files]);

  const onClickDeleteHandler = (fName, fIndex) => () => {
    if (fName) {
      if (!acceptedFiles.length) {
        // masuk sini berarti acceptedFiles kosong, menandakan file ada di server
        // kasih tau server untuk hapus file
        setFilesToBeDeleted((prevState) => {
          return [...prevState, fName];
        });
        // hapus file dari state
        setFiles((prevState) => {
          return prevState.filter((file) => {
            return file.name !== fName;
          });
        });
      } else {
        // masuk sini acceptedFiles ada isinya berarti file dari lokal belum ada di server
        // maka tidak perlu kasih tau server untuk hapus file

        // hapus file dari state
        const dt = new DataTransfer();
        const selected = [...inputRef.current.files];
        if (selected.length) {
          // handle hapus file yang sebelumnya di pilih dari open file dialog
          selected.forEach((file) => {
            file.name !== fName && dt.items.add(file); // Ambil file yang tidak di hapus
          });
        } else {
          // handle hapus file yang sebelumnya di drag n drop
          files.forEach((file) => {
            file.name !== fName && dt.items.add(file); // Ambil file yang tidak di hapus
          });
        }
        inputRef.current.files = dt.files; // Overwrite files
        setFiles([...dt.files]);
      }
    }
  };

  const thumbs = files.map((file, i) => (
    <Paper
      key={file.preview}
      variant="outlined"
      sx={{ mx: 1 }}
      className={classes.thumb}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={classes.innerThumb}>
        <img src={file.preview} alt="to be upload" className={classes.img} />
      </div>
      <Box
        sx={{
          p: 1,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Chip
            sx={{ mr: 1 }}
            label={
              <Typography
                component="span"
                variant="caption"
                color="GrayText"
                children={readableFileSize(file.size)}
              />
            }
            size="small"
            variant="outlined"
          />
          <Chip
            sx={{ overflow: "hidden" }}
            label={
              <Typography
                children={file.name}
                component="span"
                variant="caption"
                color="GrayText"
                noWrap
                sx={{ my: 1 }}
              />
            }
            size="small"
            variant="outlined"
            onDelete={
              files.length > 1 && setFilesToBeDeleted
                ? onClickDeleteHandler(file.name, i)
                : undefined
            }
          />
        </Box>
      </Box>
    </Paper>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const snakeCaseOfLabel = toSnakeCase(label);

  return (
    <>
      <div className={classes.dropzone + " " + customClassNames}>
        <div
          {...getRootProps({
            onDragLeave: () => setReadyForDrop(false),
            onDragOver: () => setReadyForDrop(true),
            style,
            className:
              classes.dropzoneWrapper +
              (readyForDrop ? " " + classes.readyForDrop : ""),
          })}
        >
          <input {...getInputProps({ name: inputName })} />
          <Typography component="p" variant="h5" sx={{ pointerEvents: "none" }}>
            Drag 'n' drop file here, or click to select file
          </Typography>
        </div>
        {CustomThumbnail ? (
          <CustomThumbnail
            onClick={() => rootRef.current.click()}
            onDragOver={() => setReadyForDrop(true)}
          />
        ) : (
          <div
            className={classes.thumbsWrapper}
            onDragOver={() => setReadyForDrop(true)}
            onClick={() => rootRef.current.click()}
          >
            {thumbs}
          </div>
        )}
      </div>
      <TextField
        error={!!fileError}
        helperText={fileError || hintText}
        margin={!!hideInput ? "none" : "normal"}
        required={!!required}
        fullWidth
        autoFocus={!!autoFocus}
        id={snakeCaseOfLabel}
        label={upperCaseFirstLetter(label)}
        name={snakeCaseOfLabel}
        value={fileName}
        onClick={openFileDialog}
        InputLabelProps={{ sx: !!hideInput ? { display: "none" } : {} }}
        InputProps={{
          sx: !!hideInput ? { margin: 0, height: 0, overflow: "hidden" } : {},
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                // onClick={(event) => event.stopPropagation()}
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                {icon ? icon : <PhotoCameraIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default memo(Dropzone);
