import Paper from "@mui/material/Paper";
import loadable from "@loadable/component";

const IndexTable = loadable(() => import("./index_component/IndexTable"));

const DownloadRequestIndex = (props)=> {
    return (
          <Paper>
            <IndexTable />
          </Paper>
      );
}
export default DownloadRequestIndex;