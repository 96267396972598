import { configureStore } from "@reduxjs/toolkit";
import confirmationDialogReducer from "../components/confirmation_dialog/confirmationDialogSlice";
import faqDetailDialogReducer from "../page/faqs/detail_components/detailDialogSlice";
import downloadDetailDialogReducer from "../page/downloads/detail_components/detailDialogSlice";
import authReducer from "../components/auth/authSlice";
import flashInfoReducer from "../components/flash_info/flashInfoSlice";
import {orderDetailSlice} from "./slice/orderDetail";

export default configureStore({
  reducer: {
    confirmationDialog: confirmationDialogReducer,
    faqDetailDialog: faqDetailDialogReducer,
    downloadDetailDialog: downloadDetailDialogReducer,
    auth: authReducer,
    flashInfo: flashInfoReducer,
    [orderDetailSlice.name]: orderDetailSlice.reducer,
  },
});
