import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { memo } from "react";

const ActionButtons = ({isLoading, backTo}) => {
    return (
        <Box sx={{ textAlign: "right" }}>
          <Button
            type="button"
            variant="contained"
            color="inherit"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
            to={backTo}
            component={Link}
            children="Back"
          />
          <Box component="span" sx={{ml: 1}}/>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
          >
            {isLoading && <CircularProgress size={20} sx={{ mr: 1 }} />}
            <Box component="span">Submit</Box>
          </Button>
        </Box>
    );
}

export default memo(ActionButtons);