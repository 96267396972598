import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import api from "../../../utils/axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Dropzone from "../../../components/image_dropzone/Dropzone";
import { setOpen as setSnackbarOpen } from "../../../components/flash_info/flashInfoSlice";
import { useDispatch } from "react-redux";
import ActionButtons from "../../../components/form_buttons/ActionButtons";
import InputField from "../../../components/input_field/InputField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import SelectMotif from "./SelectMotif";
import Grid from "@mui/material/Grid";

const DesignInspirationForm = (props) => {
  const [imageError, setImageError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [displayOrderError, setDisplayOrderError] = useState("");
  const [motifError, setMotifError] = useState("");

  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [displayOrder, setDisplayOrder] = useState("");
  const [motif, setMotif] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [filesWereDropped, setFilesWereDropped] = useState(false);

  const [files, setFiles] = useState([]);

  const navigate = useNavigate();
  const { designInspirationId } = useParams();
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    const abortController = new AbortController();
    const accessToken = localStorage.cjfiAdmTkn;
    if (location.pathname.includes("edit")) {
      (async () => {
        try {
          const res = await api.get(
            "/api/design-inspirations/" + designInspirationId,
            {
              signal: abortController.signal,
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );
          if (res.status === 200) {
            if (res.data.code === 200) {
              const { im, t, ty, d, m } = res.data.designInspiration;
              // im harus berbentuk array of object. contoh:
              // [{preview: url, name: ..., size: ..., path: ...}, {}, {}]
              // harus ada path isinya nama file
              setFiles(im); // name & size dipake di Dropzone.js
              setTitle(t);
              setType(ty);
              setDisplayOrder(d);
              setMotif(m);
            }
          }
        } catch ({ response = {} }) {
          if (!abortController.signal.aborted) {
            dispatch(
              setSnackbarOpen({ text: response.data.message, type: "error" })
            );
          }
        }
      })();
    }
    return () => {
      abortController.abort();
    };
  }, [designInspirationId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setImageError("");
    setTitleError("");
    setTypeError("");
    setDisplayOrderError("");
    setMotifError("");
    setIsLoading(true);

    const formData = new FormData(event.currentTarget);
    formData.delete("choose_image");
    // lihat: https://github.com/react-dropzone/react-dropzone/issues/131#issuecomment-264949671
    // https://github.com/ucdavis/CaesHelp/issues/64
    if (filesWereDropped) {
      files.forEach((file) => {
        formData.set("im[]", file);
      });
    }

    formData.set("ty", type);
    formData.set("m", motif ? motif : "");

    try {
      const accessToken = localStorage.cjfiAdmTkn;
      // const resCsrfToken = await api.get("/sanctum/csrf-cookie");
      if (location.pathname.includes("edit")) {
        formData.append("_method", "PATCH");
      }
      const { status, data } = await api.post(
        "/api/design-inspirations/" +
          (typeof designInspirationId === "undefined"
            ? ""
            : designInspirationId),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status === 200 || status === 201) {
        const searchParams = location.state
          ? location.state.from
            ? location.state.from
            : ""
          : "";
        navigate("/design-inspirations" + searchParams);
      }
      setIsLoading(false);
      dispatch(setSnackbarOpen({ text: data.message }));
    } catch ({ response = {} }) {
      setIsLoading(false);
      if (response.data) {
        dispatch(
          setSnackbarOpen({ text: response.data.message, type: "error" })
        );
        if (response.data.errors) {
          const errMsgs = response.data.errors;

          Object.keys(errMsgs).map((fieldName, index) => {
            const errMsg = errMsgs[fieldName][0];

            switch (fieldName) {
              case "t":
                setTitleError(errMsg);
                break;
              case "ty":
                setTypeError(errMsg);
                break;
              case "d":
                setDisplayOrderError(errMsg);
                break;
              case "m":
                setMotifError(errMsg);
                break;
              case "im":
                setImageError(errMsg);
                break;
              default:
                break;
            }
          });
        }
      }
    }
  };

  const onTitleChangeHandler = (e) => {
    setTitle(e.target.value);
  };

  const onTypeChangeHandler = (e) => {
    setType(e.target.value);
  };

  const onDisplayOrderChangeHandler = (e) => {
    setDisplayOrder(e.target.value);
  };

  const onMotifChangeHandler = (e, newValue) => {
    setMotif(newValue ? newValue.id : null);
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <Dropzone
          autoFocus
          required
          label="Choose Image"
          fileError={imageError}
          inputName="im[]"
          files={files}
          setFiles={setFiles}
          setFilesWereDropped={setFilesWereDropped}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <InputField
              fullWidth
              fieldError={titleError}
              value={title}
              onValueChanged={onTitleChangeHandler}
              label="Title"
              name="t"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectMotif
              value={motif}
              onChange={onMotifChangeHandler}
              inputProps={{
                // required: true,
                helperText: motifError,
                name: "m",
                label: "Ceramic Reference",
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              fullWidth
              fieldError={displayOrderError}
              value={displayOrder}
              onValueChanged={onDisplayOrderChangeHandler}
              label="Display Order"
              name="d"
              margin="normal"
              required={true}
              type="number"
            />
          </Grid>
        </Grid>
        <FormControl margin="normal" fullWidth error={!!typeError}>
          <FormLabel id="controlled-radio-buttons-group">Type</FormLabel>
          <RadioGroup
            aria-labelledby="controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={type}
            onChange={onTypeChangeHandler}
          >
            <FormControlLabel value={1} control={<Radio />} label="Interior" />
            <FormControlLabel value={2} control={<Radio />} label="Exterior" />
          </RadioGroup>
          <FormHelperText>{typeError}</FormHelperText>
        </FormControl>
        <ActionButtons isLoading={isLoading} backTo="/design-inspirations" />
      </Box>
    </Paper>
  );
};

export default DesignInspirationForm;
