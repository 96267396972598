import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../../utils/axios";
import { setOpen as setSnackbarOpen } from "../../../components/flash_info/flashInfoSlice";
import Dropzone from "../../../components/image_dropzone/Dropzone";
import ActionButtons from "../../../components/form_buttons/ActionButtons";
import InputField from "../../../components/input_field/InputField";

const UserForm = () => {
  const [imageError, setImageError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [roleError, setRoleError] = useState("");
  const [isActiveError, setIsActiveError] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [role, setRole] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [isActive, setIsActive] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [filesWereDropped, setFilesWereDropped] = useState(false);

  const [files, setFiles] = useState([]);
  const { userId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const abortController = new AbortController();
    const accessToken = localStorage.cjfiAdmTkn;
    if (location.pathname.includes("edit") && userId) {
      (async () => {
        try {
          const res = await api.get("/api" + location.pathname, {
            signal: abortController.signal,
            headers: { Authorization: `Bearer ${accessToken}` },
          });
          if (res.status === 200) {
            if (res.data.code === 200) {
              const { im, fn, ln, u, e, g, r, ac } = res.data.userAdmin;
              // im harus berbentuk array of object. contoh:
              // [{preview: url, name: ..., size: ..., path: ...}, {}, {}]
              // harus ada path isinya nama file
              setFiles(im); // name & size dipake di Dropzone.js
              setFirstName(fn);
              setLastName(ln);
              setUsername(u);
              setEmail(e);
              setGender(g);
              setRole(r[0] ? r[0].name : "");
              setIsActive(ac === 1 ? true : false);
            }
          }
        } catch ({ response = {} }) {
          if (!abortController.signal.aborted) {
            dispatch(
              setSnackbarOpen({ text: response.data.message, type: "error" })
            );
          }
        }
      })();
    }
    return () => {
      abortController.abort();
    };
  }, [userId]);

  useEffect(() => {
    const abortController = new AbortController();
    const accessToken = localStorage.cjfiAdmTkn;
    (async () => {
      try {
        const res = await api.get("/api/roles", {
          signal: abortController.signal,
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        if (res.status === 200) {
          if (res.data.code === 200) {
            const rl = res.data.rl ? res.data.rl : [];
            setRoleList(rl);
          }
        }
      } catch ({ response = {} }) {
        if (!abortController.signal.aborted) {
          dispatch(
            setSnackbarOpen({ text: response.data.message, type: "error" })
          );
        }
      }
    })();
    return () => {
      abortController.abort();
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setImageError("");
    setFirstNameError("");
    setLastNameError("");
    setUsernameError("");
    setEmailError("");
    setGenderError("");
    setRoleError("");
    setIsActiveError("");
    setIsLoading(true);

    const formData = new FormData(event.currentTarget);
    formData.delete("choose_image");
    // lihat: https://github.com/react-dropzone/react-dropzone/issues/131#issuecomment-264949671
    // https://github.com/ucdavis/CaesHelp/issues/64
    if (filesWereDropped) {
      files.forEach((file) => {
        formData.set("im[]", file);
      });
    }

    formData.set("ac", isActive ? 1 : 0);

    try {
      const accessToken = localStorage.cjfiAdmTkn;
      // const resCsrfToken = await api.get("/sanctum/csrf-cookie");
      let addOrEdit = 'add';
      if (location.pathname.includes("edit")) {
        formData.append("_method", "PATCH");
        addOrEdit = 'edit';
      }
      const { status, data } = await api.post(
        "/api/users/admins/" + addOrEdit +
          (typeof userId === "undefined" ? "" : ("/" + userId)),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status === 200 || status === 201) {
        const searchParams = location.state
          ? location.state.from
            ? location.state.from
            : ""
          : "";
        navigate("/users/admins" + searchParams);
      }
      setIsLoading(false);
      dispatch(setSnackbarOpen({ text: data.message }));
    } catch ({ response = {} }) {
      setIsLoading(false);
      if (response.data) {
        dispatch(
          setSnackbarOpen({ text: response.data.message, type: "error" })
        );
        if (response.data.errors) {
          const errMsgs = response.data.errors;

          Object.keys(errMsgs).map((fieldName, index) => {
            const errMsg = errMsgs[fieldName][0];

            switch (fieldName) {
              case "im":
                setImageError(errMsg);
                break;
              case "fn":
                setFirstNameError(errMsg);
                break;
              case "ln":
                setLastNameError(errMsg);
                break;
              case "u":
                setUsernameError(errMsg);
                break;
              case "e":
                setEmailError(errMsg);
                break;
              case "g":
                setGenderError(errMsg);
                break;
              case "r":
                setRoleError(errMsg);
                break;
              case "ac":
                setIsActiveError(errMsg);
                break;
              default:
                break;
            }
          });
        }
      }
    }
  };
  const onFirstNameChangeHandler = (e) => {
    setFirstName(e.target.value);
  };
  const onLastNameChangeHandler = (e) => {
    setLastName(e.target.value);
  };
  const onUsernameChangeHandler = (e) => {
    setUsername(e.target.value);
  };
  const onEmailChangeHandler = (e) => {
    setEmail(e.target.value);
  };
  const onGenderChangeHandler = (e) => {
    setGender(e.target.value);
  };
  const onRoleChangeHandler = (e) => {
    setRole(e.target.value);
  };
  const onActiveChangeHandler = (e) => {
    setIsActive(e.target.checked);
  };
  return (
    <Paper sx={{ p: 3 }}>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Dropzone
          autoFocus
          label="Choose Image"
          fileError={imageError}
          inputName="im[]"
          files={files}
          setFiles={setFiles}
          setFilesWereDropped={setFilesWereDropped}
        />
        <br />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <InputField
              fieldError={firstNameError ? true : false}
              helperText={firstNameError}
              autoComplete="first-name"
              name="fn"
              required
              fullWidth
              id="firstName"
              label="First Name"
              autoFocus
              value={firstName}
              onValueChanged={onFirstNameChangeHandler}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputField
              fieldError={lastNameError ? true : false}
              helperText={lastNameError}
              autoComplete="last-name"
              name="ln"
              fullWidth
              id="lastName"
              label="Last Name"
              value={lastName}
              onValueChanged={onLastNameChangeHandler}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputField
              fieldError={usernameError ? true : false}
              helperText={usernameError}
              required
              fullWidth
              id="username"
              label="Username"
              name="u"
              autoComplete="username"
              value={username}
              onValueChanged={onUsernameChangeHandler}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputField
              fieldError={emailError ? true : false}
              helperText={emailError}
              required
              fullWidth
              id="email"
              label="Email Address"
              name="e"
              autoComplete="email"
              value={email}
              onValueChanged={onEmailChangeHandler}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputField
              select
              fieldError={genderError ? true : false}
              helperText={genderError}
              fullWidth
              id="gender"
              label="Gender"
              name="g"
              value={gender}
              onValueChanged={onGenderChangeHandler}
              optionItems={[
                { val: "M", lbl: "Male" },
                { val: "F", lbl: "Female" },
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputField
              select
              fieldError={roleError ? true : false}
              helperText={roleError}
              required
              fullWidth
              id="role"
              label="Role"
              name="r"
              value={role}
              onValueChanged={onRoleChangeHandler}
              optionItems={roleList}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControlLabel
              control={
                <Checkbox
                  id="active"
                  checked={isActive}
                  onChange={onActiveChangeHandler}
                  inputProps={{ name: "ac" }}
                />
              }
              label="Active"
            />
          </Grid>
        </Grid>
        <ActionButtons isLoading={isLoading} backTo="/users/admins" />
      </Box>
    </Paper>
  );
};
export default UserForm;
