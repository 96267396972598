import { createSlice } from "@reduxjs/toolkit";

export const faqDetailDialogSlice = createSlice({
    name: "faqDetailDialog",
    initialState: {
        open: false,
        question: "",
        answer: "",
        createdAt: "",
        updatedAt: "",
        isLoading: false,
    },
    reducers: {
        setOpen: (state) => {
            state.open = true;
        },
        setClose: (state) => {
            state.open = false;
        },
        setQuestion: (state, action) => {
            state.question = action.payload.question;
        },
        setAnswer: (state, action) => {
            state.answer = action.payload.answer;
        },
        setCreatedAt: (state, action) => {
            state.createdAt = action.payload.createdAt;
        },
        setUpdatedAt: (state, action) => {
            state.updatedAt = action.payload.updatedAt;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload.isLoading;
        }
    }
});

export const { setOpen, setClose, setQuestion, setAnswer, setCreatedAt, setUpdatedAt, setIsLoading } = faqDetailDialogSlice.actions;

export default faqDetailDialogSlice.reducer;